import React, {useRef} from 'react'
import cn from 'classnames'
import { useViewportSpy, useMediaQuery } from 'beautiful-react-hooks'
// import { setModalOpen } from 'layouts/DefaultLayout'

import styles from './styles.module.scss'


const autoSlideMinTimeout = 4500 // ms
const animationTime = 280 // ms

const slides = {
    desktop: [
        {
            index: 0,
            src: require('./images/slide1.jpg'),
            text: 'You upload your client database.',
        },
        {
            index: 1,
            src: require('./images/slide2.jpg'),
            text: 'We provide you an offer each month from a popular local restaurant or attraction.',
        },
        {
            index: 2,
            src: require('./images/slide3.jpg'),
            text: 'You personalize the monthly postcard using our postcard tool.'
        },
        {
            index: 3,
            src: require('./images/slide4.jpg'),
            text: 'We send your monthly postcards with the local offer to your clients.'
        },
        {
            index: 4,
            src: require('./images/slide5.jpg'),
            text: 'Clients receive the offer and appreciate the thoughtful gift!'
        }
    ],
    mobile: [
        {
            index: 0,
            src: require('./images/slide1_mobile.jpg'),
            text: 'You upload your client database.',
        },
        {
            index: 1,
            src: require('./images/slide2_mobile.jpg'),
            text: 'We provide you an offer each month from a popular local restaurant or attraction.',
        },
        {
            index: 2,
            src: require('./images/slide3_mobile.jpg'),
            text: 'You personalize the monthly postcard using our postcard tool.'
        },
        {
            index: 3,
            src: require('./images/slide4_mobile.jpg'),
            text: 'We send your monthly postcards with the local offer to your clients.'
        },
        {
            index: 4,
            src: require('./images/slide5_mobile.jpg'),
            text: 'Clients receive the offer and appreciate the thoughtful gift!'
        }
    ]
}

export default function HowItWorks (props) {
    const isMobile = useMediaQuery('(max-width: 767px)')
    const ref = React.useRef()
    const [updating, setUpdating] = React.useState(false)
    const onViewPort = useViewportSpy(ref)

    const handleGetSlides = React.useCallback(() => {
        return isMobile ? slides.mobile : slides.desktop
    }, [isMobile, slides])

    const [activeSlide, setActiveSlide] = React.useState(handleGetSlides()[0])

    const handleSetSlide = React.useCallback(slide => {
        return () => {
            setUpdating(true)
            setTimeout(() => {
                setActiveSlide(slide)
            }, animationTime - (animationTime / 2))
            setTimeout(() => {
                setUpdating(false)
            }, animationTime )
        }
    }, [updating])

    React.useEffect(() => {
        if (!activeSlide) return
        handleSetSlide(handleGetSlides()[activeSlide.index])()
        // setActiveSlide(handleGetSlides()[activeSlide.index])
    }, [activeSlide, handleGetSlides, isMobile])

    React.useEffect(() => {
        if (typeof window === 'undefined') return
        if (!onViewPort) return
        if (updating) return

        const interval = window.setInterval(() => {
            if (!updating || !onViewPort) {
                const nextSlideId = ((activeSlide.index + 1) % (handleGetSlides().length))
                handleSetSlide(handleGetSlides()[nextSlideId])()
            }
        }, autoSlideMinTimeout)

        return () => window.clearInterval(interval)
    }, [onViewPort, activeSlide, handleGetSlides, updating])

    const imagesToCache = React.useMemo(() => {
        const allImages = []

        slides.desktop.forEach(slide => {
            allImages.push(`url(${slide.src})`)
        })

        slides.mobile.forEach(slide => {
            allImages.push(`url(${slide.src})`)
        })

        return allImages.join(',')
    }, slides)

    return (
        <div className={styles.howItWorks}>
            <div className={cn(styles.slides, {
                [styles.updating]: updating
            })} style={{
                'transition': `all ${animationTime}ms ease-in`,
                backgroundImage: `url(${activeSlide.src})`
            }}/>

            <div className={styles.imageCachePreload} style={{
                background: imagesToCache
            }} />

            <div className={cn(styles.container, styles.boxContainer)}>
                <div className={styles.space} />
                <div className={styles.box}>
                    <h3>From Your Friends <br /> Relationship Marketing</h3>
                    <div className={styles.subTitle}>How It Works</div>
                    <div className={styles.slideBubbles}>
                        {handleGetSlides().map((slide, index) => (
                            <div className={cn(styles.slideBubble, {
                                [styles.active]: slide.src === activeSlide.src
                            })} onClick={handleSetSlide(slide)}>
                                {index+1}
                            </div>
                        ))}
                    </div>
                    <span ref={ref} className={cn(styles.boxMessage, {
                        // [styles.updating]: updating
                    })} style={{
                        // 'transition': `all ${animationTime}ms ease-in-out`,
                    }}>
                    {activeSlide.text}
                </span>
                </div>
            </div>

            <div className={styles.informationArea}>
                <div className={styles.container}>
                    <span>
                        When your clients feel appreciated, they will work with you again and refer you new business.
                    </span>
                    <a
                        className={cn("sh-button-primary __large", styles.btn)}
                        href="https://www.fromyourfriends.com/upcoming-offers/"
                        target={"_blank"}
                    >
                        View our monthly offers
                    </a>
                </div>
            </div>
        </div>
    )
}