import React from 'react'

import coldwell from './images/coldwell.svg'
import woodBros from './images/woodBros.svg'
import semonin from './images/semonin.svg'
import edina from './images/edina.svg'
import huff from './images/huff.svg'

import styles from './styles.module.scss'

export default function RealEstateBrands (props) {
    return (
        <section className={styles.brands}>
            <div className={styles.logosContainer}>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${huff})`,
                        width: '124px',
                        height: '72px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${semonin})`,
                        width: '130px',
                        height: '72px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${coldwell})`,
                        width: '214px',
                        height: '50px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${woodBros})`,
                        width: '170px',
                        height: '64px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${edina})`,
                        width: '159px',
                        height: '48px'
                    }} />
                </div>
            </div>
        </section>
    );
};
