import React from 'react'
import MediaQuery from 'react-responsive'
import cn from 'classnames'

import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout"

import styles from './styles.module.scss'

export default function FooterRealEstateCTA (props) {
    const {dispatch} = React.useContext(Store)
    return (
        <div className={styles.container}>
            <section className={styles.footerCta}>
                <div className={styles.innerContainer}>
                    <MediaQuery minWidth={769}>
                        <div className={styles.footerCtaTitle}>
                            Join the From Your Friends relationship marketing program
                            and start building your business today <strong>(the first month is free!).</strong>
                        </div>

                        <div className={styles.footerCtaSubtitle}>
                            <a
                                href="https://www.fromyourfriends.com/"
                                target={"_blank"}
                                className={cn("sh-button-primary __large", styles.btn)}
                            >
                                Join the program
                            </a>
                        </div>
                    </MediaQuery>

                    <MediaQuery maxWidth={768}>
                        <div className={styles.footerCtaTitle}>
                            Join the From Your Friends relationship marketing program
                            and start building your business today <strong>(the first month is free!).</strong>
                        </div>

                        <div className={styles.footerCtaSubtitle}>
                            <a
                                href="https://www.fromyourfriends.com/"
                                target={"_blank"}
                                className={cn("sh-button-primary __large", styles.btn)}
                            >
                                Join the program
                            </a>
                        </div>
                    </MediaQuery>
                </div>
            </section>
        </div>
    );
};
