import React from 'react'

import Layout from 'layouts/DefaultLayout'
import SEO from 'components/SEO'

import HowItWorks from '../page-components/RealEstate/HowItWorks'
import RealEstateHero from '../page-components/RealEstate/RealEstateHero'
import RealEstateBrands from '../page-components/RealEstate/RealEstateBrands'
import RealEstateTestimonial from '../page-components/RealEstate/RealEstateTestimonial'
import FooterRealEstateCTA from '../page-components/RealEstate/FooterRealEstateCTA'

import thumbnail from 'images/real-estate-thumbnail.jpg'

export default function RealStatePage () {
    return (
        <Layout modalType="general" logoText="Marketing">
            <SEO
                title='Real Estate | Shift Marketing'
                description='Repeat and referral clients on autopilot.'
                image={`https://shiftmarketing.io${thumbnail}`}
                canonical={'https://shiftmarketing.io/real-estate'}
                ogUrl={'https://shiftmarketing.io/real-estate'}
                siteName={'Real Estate | Shift Marketing'}
            />
            <RealEstateHero />
            <RealEstateBrands />
            <HowItWorks />
            <RealEstateTestimonial />
            <FooterRealEstateCTA />
        </Layout>
    )
}
