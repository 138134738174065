import React, { useState, useRef } from 'react'
import cn from 'classnames'
import MediaQuery from 'react-responsive'

import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout"
// import { useWindowScroll, useMediaQuery, useViewportSpy } from 'beautiful-react-hooks'

import styles from './styles.module.scss'

export default function RealEstateHero (props) {
    const {dispatch} = React.useContext(Store)
    // const [scrollY, setScrollY] = useState((typeof window !== 'undefined' && window.scrollY) || 0)
    //
    // useWindowScroll(event => {
    //     setScrollY((typeof window !== 'undefined' && window.scrollY) || 0)
    // })

    return (
        <section className={styles.hero}>
            {/*<span className={styles.agent} />*/}
            {/*<div className={cn(styles.greenRect)}/>*/}
            {/*<div className={cn(styles.redRect)}/>*/}
            {/*<div className={cn(styles.yellowRect)}/>*/}
            {/*<div className={cn(styles.blueRect)} />*/}

            <div className={styles.innerSection}>
                <div className={styles.left}>
                    <div className={styles.leftInner}>
                        <MediaQuery minWidth={769}>
                            <h1 className={styles.title}>
                                Repeat and referral clients <br /> on autopilot.
                            </h1>
                            <p className={styles.subtitle}>
                                Cultivate reciprocity and build lasting <br />
                                relationships with the From Your Friends  <br />
                                relationship marketing program for real  <br />
                                estate agents.
                            </p>
                            <div className={styles.btnContainer}>
                                <button onClick={() => setModalOpen(dispatch, 'highered')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={768}>
                            <h1 className={styles.title}>
                                Repeat and referral <br /> clients on autopilot.
                            </h1>
                        </MediaQuery>
                    </div>
                </div>

                <div className={styles.right} />
                <MediaQuery maxWidth={768}>
                    <div className={styles.btnContainer}>
                        <button onClick={() => setModalOpen(dispatch, 'highered')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                    </div>
                </MediaQuery>
            </div>
        </section>
    );
};

