import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMediaQuery } from 'beautiful-react-hooks'
// import cn from 'classnames'
// import IconPrev from './IconPrev'
// import IconNext from './IconNext'

import stars from './images/stars.png'

import styles from './styles.module.scss'

const SLIDES = [
    {
        message: 'Over 150 of our agents participate and every one can tell a story of how a simple postcard brought new referrals and listings and how their customers truly appreciate the great offers.',
        firstName: 'Joe',
        lastName: 'Clement',
        position: "Broker/Owner RE/MAX Properties",
        stars: stars,
    },
    {
        message: 'I continually hear success stories from our agents telling me someone called to personally thank them for sending these postcards. We’ve had nothing but positive feedback.',
        firstName: 'Bonnie',
        lastName: 'Beddall',
        position: 'Managing Broker RE/MAX Metro',
        stars: stars,
    },
    {
        message: 'If we had to get rid of everything we’ve got (other than our website), this would be our last tool standing! Very cost effective, very classy, very valuable and it is so good for our agents.',
        firstName: 'Brad',
        lastName: 'DeVries',
        position: 'President/CEO Semonin Realtors, Rector-Hayden Realtors',
        stars: stars,
    }
]

export default function RealEstateTestimonial (props) {
    const swiperRef = useRef(null)
    const [onReachEnd, setReachEnd] = useState(false)
    const [onBeginning, setBeginning] = useState(false)
    const isMobile = useMediaQuery('(max-width: 992px)')

    const goNext = () => {
        if (!onReachEnd && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (!onBeginning && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    return (
        <section className={styles.realEstateTestimonials}>
                <div className={styles.heading}>
                    <h2 className={styles.title}>
                        Don’t just take it from us!
                    </h2>
                    <div className={styles.subtitle}>
                        Over 4,000 agents from across North America subscribe to the From<br /> Your Friends program.
                    </div>
                </div>
                <Swiper
                    spaceBetween={30}
                    centeredSlidesBounds
                    centeredSlides
                    slidesPerView={1}
                    initialSlide={2}
                    breakpoints={{
                        320: {
                            slidesPerView: 1.5,
                            spaceBetween: 22
                        },
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 2.9,
                            spaceBetween: 30
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        }
                    }}
                    onProgress={progress => {
                        setBeginning(progress.isBeginning)
                        setReachEnd(progress.isEnd)
                    }}
                    ref={swiperRef}
                >
                       <SwiperSlide style={{ width: '480px' }} />
                       {SLIDES.map(({
                           message,
                           stars,
                           firstName,
                           lastName,
                            position
                       }, i) => (
                            <SwiperSlide style={{ width: '400px' }}>
                                <div className={styles.testimonialContainer} key={i}>
                                    <div
                                        className={styles.stars}
                                        style={{
                                            backgroundImage: `url(${stars})`,
                                        }}
                                    />

                                  <div>
                                        <p className={styles.testimonialP}>
                                            {formatMessage(message, isMobile)}
                                        </p>

                                        <div className={styles.testimonialMeta}>
                                            <span>
                                                {firstName} {lastName}, &nbsp;
                                            </span>
                                            {position}
                                        </div>
                                  </div>
                                </div>
                            </SwiperSlide>
                       ))}
                        <SwiperSlide style={{ width: '480px' }} />
                </Swiper>


            {/*<span className={styles.swiperNavigationContainer}>*/}
            {/*    <div className={cn(styles.btn, styles.btnLeft, {*/}
            {/*        [styles.disable]: onBeginning,*/}
            {/*    })} onClick={goPrev}>*/}
            {/*        <IconPrev backgroundColor={'#EC1B2E'} />*/}
            {/*    </div>*/}

            {/*    <div*/}
            {/*        className={cn(styles.btn, styles.btnNext, {*/}
            {/*            [styles.disable]: onReachEnd,*/}
            {/*        })}*/}
            {/*        onClick={goNext}*/}
            {/*    >*/}
            {/*        <IconNext backgroundColor={'#EC1B2E'} />*/}
            {/*    </div>*/}
            {/*</span>*/}
        </section>
    )
}

const formatMessage = (message, slice) => {
    if (slice) {
        return `“${message.slice(0, 200)}${message.length >= 200 ? '...' : ''}”`
    }

    return `“${message}”`
}

